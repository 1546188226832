import "../cards/tournamentCard.scss";
import Card from "./Card";
import { InfoLabel, Sport } from "../../types/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CardInfo from "./CardInfo";

interface Props {
  tournament: Sport;
  headerImageSrc: string;
}

function TournamentCard({ tournament, headerImageSrc }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tournamentName = tournament.name.toLowerCase().replace(" ", "-");

  const infoLabel: InfoLabel = t("info", { returnObjects: true });
  const infoContent: InfoLabel = t((tournamentName as string) + "Info", {
    returnObjects: true,
  });

  const handleClick = () => {
    navigate(
      "/tournament/" + tournament.id + "/form/" + tournament.upcomingEvent?.id
    );
  };
  return (
    <Card styleClass={tournamentName}>
      <img src={headerImageSrc} alt={tournamentName + "HeadingImg"} />
      <div className="card-content">
        <CardInfo infoLabel={infoLabel} infoContent={infoContent} />

        {tournament.upcomingEvent && (
          <button className="card-btn" onClick={() => handleClick()}>
            {t("joinBtnText")}
          </button>
        )}
      </div>
    </Card>
  );
}

export default TournamentCard;
