import React from "react";
import "../cards/card.scss";

interface Props {
  children?: React.ReactNode;
  styleClass?: string;
}

function Card({ styleClass, children }: Props) {
  return <div className={"card " + styleClass}>{children}</div>;
}

export default Card;
