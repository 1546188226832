import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import TextInput from "../../formComponents/textInput/TextInput";
import { useEffect, useState } from "react";
import { Member } from "../../../types/types";
import MemberAddModal from "../memberAddModal/MemberAddModal";
import MemberList from "../memberList/MemberList";
import TextAreaInput from "../../formComponents/textAreaInput/TextAreaInput";
import "../tournamentForm/tournamentForm.scss";
import Consent from "../consent/Consent";
import PaymentInfo from "../paymentInfo/PaymentInfo";
import TournamentInfo from "../tournamentInfo/TournamentInfo";
import {
  SPORT_API_URL,
  postTournamentOptions,
} from "../../../helper/apiHelpers";
import Divider from "../../formComponents/divider/Divider";
import { useView } from "../../../hooks/view";
import { Breakpoints } from "../../../enums/breakpoints";
import { useNavigate } from "react-router-dom";
import RulesInfo from "../rulesInfo/RulesInfo";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
interface Props {
  tournamentName: string;
}

function TournamentForm({ tournamentName }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobileView = useView(Breakpoints.Mobile);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [members, setMembers] = useState<Member[]>([]);
  const [captainIndex, setCaptainIndex] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setLoading(true);
    const obj = {
      teamName: data.teamName,
      members: [
        members[captainIndex],
        ...members.slice(0, captainIndex),
        ...members.slice(captainIndex + 1),
      ],
      checkedRules: data.checkedRules,
    };
    
    postTournamentOptions.body = JSON.stringify(obj);

    fetch(SPORT_API_URL + "/tournament/registration", postTournamentOptions)
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            setAlertMessage(data.message.length > 0 ? data.message[0] : "");
            throw new Error(response.statusText);
          });
        }

        return response.json();
      })
      .then((data) => {
        setLoading(false);
        navigate("/" + tournamentName + "/links");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (alertMessage !== "") showAlert();
  }, [alertMessage]);

  const showAlert = () => {
    setVisibleAlert(true);

    setTimeout(() => {
      setVisibleAlert(false);
      setAlertMessage("");
    }, 4000);
  };

  const addMember = (member: Member) => {
    setMembers((prevMembers) => {
      if (prevMembers.length < 8) return [...prevMembers, member];
      else return prevMembers;
    });
  };

  const deleteMember = (index: number) => {
    if (captainIndex === index) setCaptainIndex(0);

    setMembers((prevMembers) => {
      return [...prevMembers.slice(0, index), ...prevMembers.slice(index + 1)];
    });
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(onSubmit)} className="tournament-form">
        <fieldset className="tournament-main-inputs">
          <TournamentInfo />
          <RulesInfo />

          <TextInput
            register={register}
            error={errors?.teamName}
            inputName="teamName"
            label={t("teamName") as string}
            required={true}
            minLen={2}
            maxLen={30}
          />

          <MemberList
            members={members}
            cptnIdx={captainIndex}
            setCptnIdx={setCaptainIndex}
            setShowModal={setShowModal}
            deleteMember={deleteMember}
          />

          <TextAreaInput
            register={register}
            error={errors?.additionalQuestion}
            label={t("additionalQuestion") as string}
            inputName="additionalQuestion"
          />
          <Divider />
        </fieldset>
        <PaymentInfo />

        <Consent
          register={register}
          error={errors?.checkedRules}
          inputName="checkedRules"
        />

        {!loading ? (
          <input
            type="submit"
            disabled={members.length < 6}
            value={t("send") as string}
            className={
              members.length < 6 ? "submit-btn btn-disabled" : "submit-btn"
            }
          />
        ) : (
          <button className="submit-btn btn-disabled centered">
            <TailSpin
              height="20"
              width="20"
              color="black"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </button>
        )}
      </form>

      {showModal && (
        <MemberAddModal
          open={showModal}
          members={members}
          addMember={addMember}
          toggle={setShowModal}
        />
      )}

      <Snackbar open={visibleAlert}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default TournamentForm;

interface FormValues {
  teamName: string;
  additionalQuestion: string;
  checkedRules: boolean;
}
