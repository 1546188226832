import { useTranslation } from "react-i18next";
import "../tournamentInfo/tournamentInfo.scss";
import Divider from "../../formComponents/divider/Divider";
function TournamentInfo() {
  const { t } = useTranslation();

  return (
    <section className="tournament-info">
      <h2>{t("hello")}</h2>
      <p>{t("tInfoParagraph1")}</p>
      <p>{t("tInfoParagraph2")}</p>
      <p>{t("tInfoParagraph3")}</p>
      <p>{t("tInfoParagraph4")}</p>
    </section>
  );
}

export default TournamentInfo;
