import { useTranslation } from "react-i18next";
import "../paymentInfo/paymentInfo.scss";
import Divider from "../../formComponents/divider/Divider";

function PaymentInfo() {
  const { t } = useTranslation();

  return (
    <fieldset className="payment-info-fieldset">
      <h2>{t("paymentInfoHeading")}</h2>
      <h3>!{t("attention")}!</h3>
      <p>
        {t("paymentInfoParagraph1Part1")} <span>500 kč</span> na{" "}
        <span>{t("onAccountNumber")}: 2401415185/2010</span>.{" "}
        {t("paymentInfoParagraph1Part2")}
      </p>
      <p>{t("paymentInfoParagraph2")}</p>
      <Divider />
    </fieldset>
  );
}

export default PaymentInfo;
