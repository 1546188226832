import React, { useEffect, useState } from "react";
import { Languages } from "../enums/languages";
import { useTranslation } from "react-i18next";
import csFlag from "../assets/images/icons/flags/cz.svg";
import enFlag from "../assets/images/icons/flags/gb.svg";
import vnFlag from "../assets/images/icons/flags/vn.svg";
import "../assets/styles/layout.scss";
import VU_logo from "../assets/images/VietupLogo.svg";
import { Link } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
  bg?: string;
}

function Layout({ children, bg = "other" }: Props) {
  const { t, i18n } = useTranslation();
  const [selectedFlag, setSelectedFlag] = useState(0);

  useEffect(() => {
    document.body.classList.add(bg + "-background");

    return function cleanup() {
      document.body.classList.remove(bg + "-background");
    };
  });

  useEffect(() => {
    const lang = localStorage.getItem("VUwebLanguage");

    if (!lang) {
      setSelectedFlag(0);
      localStorage.setItem("VUwebLanguage", Languages.CS);
    } else {
      switch (lang) {
        case Languages.EN:
          setSelectedFlag(1);
          break;
        case Languages.VN:
          setSelectedFlag(2);
          break;
        default:
          setSelectedFlag(0);
      }

      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  const handleClick = (language: string, index: number) => {
    setSelectedFlag(index);

    const lang = localStorage.getItem("VUwebLanguage");

    if (lang && lang !== language) {
      localStorage.setItem("VUwebLanguage", language);
      i18n.changeLanguage(language);
    }
  };

  return (
    <>
      <header>
        <Link to="/">
          <img src={VU_logo} alt={"viet-up-logo"} className="vu-logo" />
        </Link>
        <button onClick={() => handleClick(Languages.CS, 0)}>
          <img
            src={csFlag}
            alt="cs-flag"
            className={selectedFlag === 0 ? "selectedFlag flag" : "flag"}
          />
        </button>

        <button onClick={() => handleClick(Languages.EN, 1)}>
          <img
            src={enFlag}
            alt="en-flag"
            className={selectedFlag === 1 ? "selectedFlag flag" : "flag"}
          />
        </button>
        {/*<button onClick={() => handleClick(Languages.VN, 2)}>
          <img
            src={vnFlag}
            alt="vn-flag"
            className={selectedFlag === 2 ? "selectedFlag flag" : "flag"}
          />
        </button> */}
      </header>

      <main>{children}</main>
    </>
  );
}

export default Layout;
