import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextInputOptions } from "../../../types/types";
import "../textAreaInput/textAreaInput.scss";
import { useView } from "../../../hooks/view";
import { Breakpoints } from "../../../enums/breakpoints";

interface Props {
  register: any;
  error?: FieldError | undefined;
  inputName?: string;
  label?: string;
  placeholder?: string;
  minLen?: number;
  maxLen?: number;
  required?: boolean;
  validate?: (arg0: string) => boolean;
}

function TextAreaInput({
  register,
  error,
  inputName,
  label,
  placeholder,
  minLen,
  maxLen = 1024,
  required = false,
}: Props) {
  const isMobileView = useView(Breakpoints.Mobile);
  const { t } = useTranslation();
  let options: TextInputOptions = {
    required: required,
  };

  if (minLen) {
    options.minLength = {
      value: minLen,
      message: t("minLengthInput") + minLen,
    };
  }

  if (maxLen) {
    options.maxLength = {
      value: maxLen,
      message: t("maxLengthInput") + maxLen,
    };
  }

  return (
    <div className={isMobileView ? "mobile-view-input" : "desktop-view-input"}>
      {label && <label htmlFor={inputName}>{label}:</label>}
      <textarea
        rows={5}
        cols={40}
        placeholder={placeholder}
        {...register(inputName, options)}
        className="text-area-input"
        id={inputName}
      />
      {error?.message && <p className="errorMessage">{error?.message}</p>}
      {error?.type === "required" && (
        <p className="errorMessage">{t("mandatoryField")}</p>
      )}
    </div>
  );
}

export default TextAreaInput;
