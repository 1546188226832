export const parseDate = (date: string) => {
  const res = {
    date: "",
    day: 0,
    time: "",
  };

  const d = new Date(date);
  res.date = d.getDate() + ". " + (d.getMonth() + 1) + ".";
  res.time = "" + d.getHours();
  res.day = d.getDay(); // 0 - sunday, ...

  const mins = d.getMinutes();
  if (mins > 0) {
    res.time = res.time + ":" + mins;
  }

  return res;
};
