import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RegistrationForm from "../components/RegistrationForm";
import EventInformation from "../components/EventInformation";
import { RegistrationResponse, Event } from "../types/types";
import { SPORT_API_URL, getOptions } from "../helper/apiHelpers";
import { DateRangeSharp } from "@mui/icons-material";

function FormPage() {
  const { t } = useTranslation();

  let { sport, sportId, eventId } = useParams();

  const [formSentSuccess, setFormSentSuccess] = useState(false);
  const [event, setEvent] = useState<Event>();
  const [registrationResponse, setRegistrationResponse] =
    useState<RegistrationResponse>();

  useEffect(() => {
    if (sportId) {
      fetch(SPORT_API_URL + "/event/sport/" + sportId, getOptions)
        .then((response) => response.json())
        .then((data) => {
          // sorting by from date descending
          const sorted = data.dates.sort(function (a: Event, b: Event) {
            return new Date(b.from).getTime() - new Date(a.from).getTime();
          });

          //set closest event to the date
          let i = 0,
            res = 0;
          while (true) {
            const today = new Date();
            if (new Date(sorted[i].from).getTime() < today.getTime()) {
              res = i - 1;
              break;
            }

            i++;
          }

          if (res < 0) setEvent(sorted[0]);
          else setEvent(sorted[res]);
        })
        .catch((err) => {
          console.log(err.message);
          //navigate to error page
        });
    }
  }, [sportId]);

  //formSentSuccess ? location.state.sport :
  return (
    <Layout bg={"form"}>
      {formSentSuccess && event ? (
        <EventInformation
          event={event}
          sport={sport!}
          paymentInfo={registrationResponse?.payment}
        />
      ) : (
        <RegistrationForm
          eventId={eventId!}
          sport={sport!}
          formSent={setFormSentSuccess}
          setRes={setRegistrationResponse}
        />
      )}
    </Layout>
  );
}

export default FormPage;
