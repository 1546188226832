export const SPORT_API_URL = "https://sport-api.vps.trieu.cz/sport";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/cz.trieu.vietup-v1+json",
};

export const getOptions = {
  method: "GET",
  headers: headers,
};

export const postRegistrationOptions = {
  method: "POST",
  headers: headers,
  body: JSON.stringify({
    name: "",
    email: "",
    phoneNumber: "",
    rules: false,
    agreement: false,
    newsletter: false,
    paymentType: "",
  }),
};

export const postTournamentOptions = {
  method: "POST",
  headers: headers,
  body: JSON.stringify({
    teamName: "",
    members: [],
    checkedRules: true,
  }),
};
