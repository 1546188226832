import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextInput from "../../formComponents/textInput/TextInput";
import "../consent/consent.scss";

interface Props {
  register: any;
  error?: FieldError | undefined;
  inputName?: string;
  placeholder?: string;
}

function Consent({ register, error, inputName }: Props) {
  const { t } = useTranslation();

  return (
    <fieldset className="consent-fieldset">
      <h2>{t("consentHeading")}</h2>
      <p>{t("consentParagraph1")}</p>
      <p>{t("consentParagraph2")}</p>
      <label htmlFor="rules">
        <a
          href="https://vietup.cz/souhlas-se-zpracovanim-osobnich-udaju/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("rules")}
        </a>
      </label>
      <input
        {...register(inputName, { required: true })}
        type="checkbox"
        id={inputName}
        name={inputName}
      />

      {error?.type === "required" && (
        <p className="errorMessage">{t("mandatoryField")}</p>
      )}
    </fieldset>
  );
}

export default Consent;
