import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextInputOptions } from "../../../types/types";
import { handleEmailValidation } from "../../../helper/validation";
import "../textInput/textInput.scss";

interface Props {
  register: any;
  error?: FieldError | undefined;
  inputName?: string;
  required?: boolean;
}

function EmailInput({ register, error, inputName, required = false }: Props) {
  const { t } = useTranslation();

  let options: TextInputOptions = {
    required: required,
    validate: handleEmailValidation,
  };

  return (
    <>
      <input
        {...register(inputName, options)}
        className="form-text-input"
        placeholder={t("emailPlaceholder") as string}
      />
      {error && !(error?.type === "required") && (
        <p className="errorMessage">{t("invalidEmail")}</p>
      )}
      {error?.type === "required" && (
        <p className="errorMessage">{t("mandatoryField")}</p>
      )}
    </>
  );
}

export default EmailInput;
