import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "../assets/styles/eventInformation.scss";
import { useView } from "../hooks/view";
import { Event, InfoLabel, PaymentInfo } from "../types/types";
import { Breakpoints } from "../enums/breakpoints";
import { parseDate } from "../helper/parseDate";

interface Props {
  event: Event;
  sport: string;
  paymentInfo?: PaymentInfo;
}

function EventInformation({ event, sport = "other", paymentInfo }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bpFlag = useView(Breakpoints.EventInfo);
  const infoLabel: InfoLabel = t("info", { returnObjects: true });
  const from = parseDate(event.from);
  const to = parseDate(event.to);

  const placeInfo = () => {
    return (
      <div className={"placeInfo " + sport}>
        <table>
          <tbody>
            <tr>
              <th>{infoLabel.where}</th>
              <th>{event.location}</th>
            </tr>
            <tr>
              <th>{infoLabel.when}</th>
              <th>{from.date}</th>
            </tr>
            <tr>
              <th>{infoLabel.whatTime}</th>
              <th>
                {from.time} - {to.time}
              </th>
            </tr>
          </tbody>
        </table>

        <div className="contact">
          <h3>{t("contactPerson")}:</h3>
          <p>{event.contactName}</p>
          <p>{event.contactEmail}</p>
          <p>{event.contactPhoneNumber}</p>
        </div>
      </div>
    );
  };

  const payInfo = () => {
    return (
      <div className={"paymentInfo " + sport}>
        <table>
          <tbody>
            <tr>
              <th>{t("accountNumber")}:</th>
              <th>2401415185/2010</th>
            </tr>
            <tr>
              <th>{t("amount")}:</th>
              <th>130 Kč</th>
            </tr>
            <tr>
              <th>{t("vs")}:</th>
              <th>{paymentInfo?.variableSymbol}</th>
            </tr>
          </tbody>
        </table>

        <img src={paymentInfo?.dataBase64} alt="QR-payment" className="qr" />

        <button className="finishBtn" onClick={handleClick}>
          {t("finish")}
        </button>
      </div>
    );
  };

  const content = () => {
    if (bpFlag) {
      return (
        <>
          {placeInfo()}
          {payInfo()}
        </>
      );
    }

    return (
      <div className="contentWrapper">
        {placeInfo()}
        {payInfo()}
      </div>
    );
  };

  const handleClick = () => {
    navigate("/" + sport + "/links");
  };

  return (
    <section className="informationSection">
      <img
        src={require("../assets/images/" + sport.toLowerCase() + "-logo.svg")}
        alt="sports-event-heading"
      />
      <p>{t("pleasePay")}</p>

      {content()}
    </section>
  );
}

export default EventInformation;
