import { useTranslation } from "react-i18next";
import { Member } from "../../../types/types";
import AddIcon from "@mui/icons-material/Add";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ChangeEvent } from "react";
import "../memberList/memberList.scss";
import { useView } from "../../../hooks/view";
import { Breakpoints } from "../../../enums/breakpoints";
import Divider from "../../formComponents/divider/Divider";
import { isDisabled } from "@testing-library/user-event/dist/utils";

interface Props {
  members: Member[];
  cptnIdx: number;
  setCptnIdx: (arg0: number) => void;
  setShowModal: (b: boolean) => void;
  deleteMember: (index: number) => void;
}

function MemberList({
  members,
  cptnIdx,
  setCptnIdx,
  setShowModal,
  deleteMember,
}: Props) {
  const { t } = useTranslation();
  const isMobileView = useView(Breakpoints.Mobile);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCptnIdx(parseInt(e.target.value));
  };

  const closeButton = (index: number) => {
    return (
      <button
        type="button"
        onClick={() => {
          deleteMember(index);
        }}
        title={t("remove") as string}
        className="delete-btn warning"
      >
        <CloseRoundedIcon sx={{ color: "white" }} />
      </button>
    );
  };

  const memberList = members.map((member, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}.</td>
        <td>
          {member.name} {member.lastName}
        </td>
        <td>{member.email}</td>
        <td>
          <label htmlFor="captain"></label>
          <input
            type="radio"
            name="captain"
            value={index}
            checked={cptnIdx === index}
            onChange={handleChange}
          ></input>
        </td>
        <td>{closeButton(index)}</td>
      </tr>
    );
  });

  const mobileMemberList = members.map((member, index) => {
    return (
      <tr key={index} className="mobile-member-list-item">
        <td className="td-index">{index + 1}.</td>
        <td>
          <div className="mobile-member-info">
            <span>
              {member.name} {member.lastName}
            </span>
            <span>{member.email}</span>
          </div>
        </td>
        <td>
          <label htmlFor="captain"></label>
          <input
            type="radio"
            name="captain"
            value={index}
            checked={cptnIdx === index}
            onChange={handleChange}
          ></input>
        </td>
        <td className="td-close-btn">{closeButton(index)}</td>
      </tr>
    );
  });

  return (
    <div className="member-list-container">
      <div className="member-list-heading-container">
        <h3 className={isMobileView ? "member-list-mobile-heading" : ""}>
          {t("MemberListHeading")}
        </h3>

        <button
          type="button"
          title={t("addMember") as string}
          disabled={members.length >= 8}
          onClick={() => {
            setShowModal(true);
          }}
          className={
            members.length >= 8
              ? "add-btn primary btn-disabled"
              : "add-btn primary"
          }
        >
          <AddIcon sx={{ color: "white" }} />
        </button>
      </div>

      {isMobileView ? (
        <table className="mobile-member-list-table">
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td>{t("teamCaptain")}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>{mobileMemberList}</tbody>
        </table>
      ) : (
        <table className="member-list-table">
          <thead>
            <tr>
              <td></td>
              <td>{t("namePlaceholder")}</td>
              <td>{t("emailPlaceholder")}</td>
              <td>{t("teamCaptain")}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>{memberList}</tbody>
        </table>
      )}
    </div>
  );
}

export default MemberList;
