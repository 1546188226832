import { useTranslation } from "react-i18next";
import Divider from "../../formComponents/divider/Divider";
import "../rulesInfo/rulesInfo.scss";

function RulesInfo() {
  const { t } = useTranslation();
  const list = t("tournamentRules", { returnObjects: true });

  const listItems =
    Array.isArray(list) &&
    list.map((item, index) => {
      return <li key={index}>{item}</li>;
    });

  return (
    <section className="rules-info">
      <h2>{t("tournamentRulesHeading")}</h2>
      <p>{t("forRegistration")}</p>
      <ul className="rules-list">{Array.isArray(list) ? listItems : <></>}</ul>
      <Divider />
    </section>
  );
}

export default RulesInfo;
