import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from './en/en.json'
import translationsCS from './cs/cs.json'

//TODO: localStorage

const resources = {
  en: {
    translation: translationsEN
  },
  cs: {
    translation: translationsCS
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'cs', // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
  
  export default i18n;