import Layout from "../components/Layout";
import SportCard from "../components/cards/SportCard";
import { useTranslation } from "react-i18next";
import "../assets/styles/homePage.scss";
import { useEffect, useRef, useState } from "react";
import { getOptions, SPORT_API_URL } from "../helper/apiHelpers";
import { Event, Sport } from "../types/types";
import React from "react";
import TournamentCard from "../components/cards/TournamentCard";

function HomePage() {
  const { t } = useTranslation();
  const [sports, setSports] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const ref = useRef<null | HTMLDivElement>(null);
  const [isOverFlown, setIsOverflown] = useState(true);

  const checkOverFlow = () => {
    setTimeout(() => {
      if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
        setIsOverflown(true);
        return;
      }

      setIsOverflown(false);
      return;
    }, 1000);
  };

  const handleOverFlow = () => {
    checkOverFlow();
  };

  useEffect(() => {
    handleOverFlow();
  }, [ref.current]);

  useEffect(() => {
    fetch(SPORT_API_URL + "/sports", getOptions)
      .then((response) => {
        if (response.ok) return response.json();
        else throw new Error("Response is not ok.");
      })
      .then((data) => {
        setSports(
          data.sports.filter((sport: Sport) => {
            return !sport.name.toLowerCase().includes("tournament");
          })
        );

        setTournaments(
          data.sports.filter((sport: Sport) => {
            return sport.name.toLowerCase().includes("tournament");
          })
        );
      })
      .catch((err) => {
        console.log(err.message);
        //navigate to error page
      });

    window.addEventListener("resize", handleOverFlow);

    return () => {
      window.removeEventListener("resize", handleOverFlow);
    };
  }, []);

  const sportCards = sports.map((item: Sport, index) => {
    return (
      <SportCard
        key={index}
        sport={item.name.toLowerCase()}
        sportId={item.id}
        upcomingEvent={item.upcomingEvent}
        buttonFlag={true}
        info={true}
        checkMarks={true}
        headerImageSrc={require("../assets/images/" +
          item.name.toLowerCase() +
          "-logo.svg")}
      />
    );
  });

  const tournamentCards = tournaments.map((item: Sport, index) => {
    return (
      <TournamentCard
        key={index}
        tournament={item}
        headerImageSrc={require("../assets/images/" +
          item.name.toLowerCase() +
          "-logo.svg")}
      />
    );
  });

  //mousedown = click
  const handleMouseDown = (e: React.MouseEvent<Element, MouseEvent>) => {
    setDragActive(true);
  };

  const handleMouseUp = (e: React.MouseEvent<Element, MouseEvent>) => {
    setDragActive(false);
  };

  const handleMouseMove = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (dragActive && ref.current) {
      ref.current.scrollLeft += -e.movementX;
    }
  };

  const handleMouseLeave = (e: React.MouseEvent<Element, MouseEvent>) => {
    setDragActive(false);
  };

  return (
    <Layout>
      <section className="welcome-wrapper">
        <h1>
          {t("hello")}
          <br />
          {t("welcome")}
        </h1>
      </section>
      <section className="sport-cards-wrapper">
        <div
          className={
            isOverFlown ? "sport-cards-drag draggable" : "sport-cards-drag"
          }
          ref={ref}
          onMouseMove={(e) => {
            handleMouseMove(e);
          }}
          onMouseLeave={(e) => {
            handleMouseLeave(e);
          }}
          onMouseDown={(e) => {
            handleMouseDown(e);
          }}
          onMouseUp={(e) => {
            handleMouseUp(e);
          }}
        >
          {/*tournaments.length > 0 && tournamentCards */}
          {sports.length > 0 && sportCards}

          <SportCard
            sport={"other"}
            buttonFlag={false}
            header={t("otherSports").toString()}
          />
        </div>
      </section>
    </Layout>
  );
}

export default HomePage;
