export const handleEmailValidation = (email: string) => {
  const isValidEmail = (email: string) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  return isValidEmail(email);
};

export const handlePhoneValidation = (phone: string) => {
  const isValidPhoneNumber = (phone: string) => /^[0-9]{9}$/.test(phone);

  return isValidPhoneNumber(phone);
};
