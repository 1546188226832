import { useTranslation } from "react-i18next";
import badmintonLogo from '../assets/images/icons/sportLogos/badminton.svg';
import basketBallLogo from '../assets/images/icons/sportLogos/basketball.svg';
import futsalLogo from '../assets/images/icons/sportLogos/football.svg';
import dodgeBallLogo from '../assets/images/icons/sportLogos/volleyball.svg';
import '../assets/styles/sportList.scss';

function SportList() {
    const { t } = useTranslation();

    const sportLogos = [basketBallLogo, futsalLogo, badmintonLogo, dodgeBallLogo];
    const sports = t('otherSportsList', { returnObjects: true});

    const sportsList = Array.isArray(sports) && sports.map((item, index) => {
        return (
            <li key={index} className='sport-list-item'>
                <img src={sportLogos[index]} alt={'sport' + index}/>
                <p className='sport-list-text'>{item}</p>
            </li>
        )
    });

    return (
        <ul className='sport-list'>
            {sportsList}
        </ul>
    )
}

export default SportList;