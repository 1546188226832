import { useEffect, useState } from "react";
import { isView } from "../helper/checkView";

// Returns boolean if current view is mobile.

export function useView(breakpoint: number) {
  const [bpView, setBpView] = useState(true);

  useEffect(() => {
    const handleResize = () => {
        setBpView(isView(breakpoint));
    };

    window.addEventListener("resize", handleResize);
    setBpView(isView(breakpoint));

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return bpView;
}
