import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import FormPage from "./pages/FormPage";
import LinksPage from "./pages/LinksPage";
import TournamentFormPage from "./pages/TournamentFormPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/tournament/:tId/form/:eventId"
          element={<TournamentFormPage />}
        />{" "}
        <Route path="/:sport/:sportId/form/:eventId" element={<FormPage />} />{" "}
        {/* TODO: change path */}
        <Route path="/:sport/links" element={<LinksPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </div>
  );
}

export default App;
