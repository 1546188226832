import { useEffect, useState } from "react";

// Returns true when user clicked outside of modal window

export function useModalClose(openModal: boolean, className: string) {
  const [closeModal, setCloseModal] = useState(false);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as Element;

      if (openModal && target.className.toString().indexOf(className) !== -1) {
        setCloseModal(true);
      }
    };

    const handleEsc = (e: KeyboardEvent) => {
      if (openModal && e.key === "Escape") setCloseModal(true);
    };

    //window.addEventListener("keydown", handleEsc); - DOESNT WORK (AT LEAST IN EDGE)
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
      //window.removeEventListener("keydown", handleEsc);
    };
  });

  return closeModal;
}
