

import Layout from '../components/Layout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import '../assets/styles/linksPage.scss';
import { useView } from '../hooks/view';
import { Breakpoints } from '../enums/breakpoints';

function LinksPage () {
    const { t } = useTranslation();
    let { sport } = useParams();
    const isMobileView = useView(Breakpoints.Mobile);

    return (
        <Layout bg={sport}>
            <h1 className='linksHeader'><img src={require('../assets/images/' + sport?.toLowerCase() + '-logo.svg')} alt='sports-event-heading'/></h1>
            <p className='linksFormDone'>{t('formDone')}</p>

            <div className='linksContent'>
                {isMobileView ? 
                    <div>
                        <p>{t('eshop')}</p>
                        <a href="https://vietup.cz/obchod/" target="_blank" rel="noopener noreferrer">{t('eshopLink')}</a>
                        <p>{t('fbGroup')}</p>
                        <a href="https://www.facebook.com/groups/vietupsport" target="_blank">{t('fbGroupLink')}</a>
                    </div>
                :
                    <table>
                        <thead>
                            <tr>
                                <th>{t('eshop')}</th>
                                <th>{t('fbGroup')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    <a href="https://vietup.cz/obchod/" target="_blank" rel="noopener noreferrer">{t('eshopLink')}</a>
                                </th>
                                <th>
                                    <a href="https://www.facebook.com/groups/vietupsport" target="_blank">{t('fbGroupLink')}</a>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
        </Layout>
    )
}

export default LinksPage;