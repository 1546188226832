import Layout from "../components/Layout";
import TournamentForm from "../components/volleyballTournament/tournamentForm/TournamentForm";

function TournamentFormPage() {
  return (
    <Layout bg={"volleyball-tournament"}>
      <TournamentForm tournamentName="volleyball-tournament" />
    </Layout>
  );
}

export default TournamentFormPage;
