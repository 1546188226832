import { useForm } from "react-hook-form";
import TextInput from "../../formComponents/textInput/TextInput";
import { useTranslation } from "react-i18next";
import EmailInput from "../../formComponents/textInput/EmailInput";
import { Member } from "../../../types/types";
import { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "../memberAddModal/memberAddModal.scss";
import { useModalClose } from "../../../hooks/modalClose";

interface Props {
  open: boolean;
  members: Member[];
  addMember: (memberName: Member) => void;
  toggle: (bool: boolean) => void;
}

function MemberAddModal({ open, members, addMember, toggle }: Props) {
  const { t } = useTranslation();
  const close = useModalClose(open, "MuiModal-backdrop");
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors, isSubmitSuccessful },
  } = useForm<Member>();

  const onSubmit = (data: Member) => {
    if (members.length >= 7) toggle(false);

    addMember({
      name: data.name,
      lastName: data.lastName,
      email: data.email,
    });
  };

  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    if (close) toggle(false);
  }, [close, toggle]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ name: "", lastName: "", email: "" });

      // CLOSE this modal window
      if (!isChecked) toggle(false);
    }
  }, [formState, reset, toggle, isChecked]);

  const handleCheck = () => {
    setIsChecked((prevIsChecked) => {
      return !prevIsChecked;
    });
  };

  return (
    <Modal open={open} className="modal-form">
      <Box className="modal-box">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-form-header">
            <h3>{t("addMember")}</h3>

            <button
              type="button"
              onClick={() => {
                toggle(false);
              }}
              className="close-btn warning"
            >
              <CloseRoundedIcon sx={{ color: "white" }} />
            </button>
          </div>

          <TextInput
            register={register}
            error={errors?.name}
            inputName="name"
            placeholder={t("firstNamePlaceholder") as string}
            required={true}
          />

          <TextInput
            register={register}
            error={errors?.lastName}
            inputName="lastName"
            placeholder={t("lastNamePlaceholder") as string}
            required={true}
          />

          {
            <EmailInput
              register={register}
              error={errors?.email}
              inputName="email"
              required={true}
            />
          }

          <span>
            <label htmlFor="addNextMember">{t("addNextMember")}</label>
            <input
              {...register}
              type="checkbox"
              id="addNextMember"
              name="addNextMember"
              checked={isChecked}
              onChange={handleCheck}
            />
          </span>

          <input
            type="submit"
            value={t("add") as string}
            className="modal-add-btn primary"
          />
        </form>
      </Box>
    </Modal>
  );
}

export default MemberAddModal;
