import "../cards/cardInfo.scss";

interface Props {
  infoLabel: { when: string; where: string; whatTime: string };
  infoContent: { when: string; date: string; where: string; whatTime: string };
}

function CardInfo({ infoLabel, infoContent }: Props) {
  return (
    <table className="card-info">
      <tbody>
        <tr>
          <th className="card-info-label">{infoLabel.where}</th>
          <th>{infoContent.where}</th>
        </tr>
        <tr>
          <th className="card-info-label">{infoLabel.when}</th>
          <th>{infoContent.when} - {infoContent.date}</th>
        </tr>
        <tr>
          <th className="card-info-label">{infoLabel.whatTime}</th>
          <th>{infoContent.whatTime}</th>
        </tr>
      </tbody>
    </table>
  );
}

export default CardInfo;
