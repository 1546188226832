import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SPORT_API_URL, postRegistrationOptions } from "../helper/apiHelpers";
import { randomizeQuestion } from "../helper/random";
import {
  handleEmailValidation,
  handlePhoneValidation,
} from "../helper/validation";
import { FormValues, RegistrationResponse } from "../types/types";
import "../assets/styles/form.scss";
import { useView } from "../hooks/view";
import { Breakpoints } from "../enums/breakpoints";
import Snackbar from "@mui/material/Snackbar";
import meetUp from "../assets/images/icons/meetup.svg";
import bodyMind from "../assets/images/icons/bodyMind.svg";
import motivation from "../assets/images/icons/motivation.svg";
import networking from "../assets/images/icons/networking.svg";
import { Alert } from "@mui/material";

interface Props {
  eventId: string;
  sport: string;
  formSent: (success: boolean) => void;
  setRes: (res: RegistrationResponse) => void;
}

function RegistrationForm({ eventId, sport, formSent, setRes }: Props) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const isMobileView = useView(Breakpoints.Mobile);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setDisabled(true);

    const obj = {
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      checkedRules: data.rules,
      agreement: data.agreement,
      newsletter: false, //data.newsletter,
      paymentType: "CARD",
    };

    postRegistrationOptions.body = JSON.stringify(obj);

    fetch(
      SPORT_API_URL + "/registration/event/" + eventId,
      postRegistrationOptions
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            setAlertMessage(data.message.length > 0 ? data.message[0] : "");
            throw new Error(response.statusText);
          });
        }

        return response.json();
      })
      .then((data) => {
        formSent(true);
        setRes(data);
        setDisabled(false);
      })
      .catch((err) => {
        setDisabled(false);
      });
  };

  useEffect(() => {
    if (alertMessage !== "") showAlert();
  }, [alertMessage]);

  const showAlert = () => {
    setVisibleAlert(true);

    setTimeout(() => {
      setVisibleAlert(false);
      setAlertMessage("");
    }, 4000);
  };

  return (
    <section className="formSection">
      {!isMobileView && (
        <div className={"aboutEvent " + sport}>
          <img
            className="aboutEventHeading"
            src={require("../assets/images/" +
              sport.toLowerCase() +
              "-logo.svg")}
            alt="sport"
          />

          <div className="aboutIcons">
            <img src={bodyMind} alt="bodyMind" />
            <p>{t("bodyMind")}</p>
          </div>

          <div className="aboutIcons">
            <img src={meetUp} alt="meetUp" />
            <p>{t("meetUp")}</p>
          </div>

          <div className="aboutIcons">
            <img src={networking} alt="netWorking" />
            <p>{t("networking")}</p>
          </div>

          <div className="aboutIcons">
            <img src={motivation} alt="motivation" />
            <p>{t("motivation")}</p>
          </div>
        </div>
      )}

      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <h2>{t("fillForm")}</h2>

        <input
          placeholder={t("namePlaceholder") as string}
          {...register("name", {
            required: true,
            minLength: {
              value: 2,
              message: t("minLengthInput") + "2",
            },
            maxLength: {
              value: 30,
              message: t("maxLengthInput") + "30",
            },
          })}
          className="formTextInput"
        />
        {errors.name?.message && (
          <p className="errorMessage">{errors.name?.message}</p>
        )}
        {errors.name?.type === "required" && (
          <p className="errorMessage">{t("mandatoryField")}</p>
        )}

        <input
          {...register("email", {
            required: true,
            validate: handleEmailValidation,
          })}
          className="formTextInput"
          placeholder={t("emailPlaceholder") as string}
        />
        {errors.email && !(errors.email?.type === "required") && (
          <p className="errorMessage">{t("invalidEmail")}</p>
        )}
        {errors.email?.type === "required" && (
          <p className="errorMessage">{t("mandatoryField")}</p>
        )}

        <input
          {...register("phoneNumber", {
            required: false,
            validate: handlePhoneValidation,
          })}
          className="formTextInput"
          placeholder={t("phonePlaceholder") as string}
        />
        {errors.phoneNumber && (
          <p className="errorMessage">{t("invalidPhoneNumber")}</p>
        )}

        <fieldset className="checkboxFieldset">
          <span>
            <input
              {...register("rules", { required: true })}
              type="checkbox"
              id="rules"
              name="rules"
              className="clickElements"
            />
            <label>
              <a
                href="https://vietup.cz/souhlas-se-zpracovanim-osobnich-udaju/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("rules")}
              </a>
            </label>
            {errors.rules?.type === "required" && (
              <p className="errorMessage">{t("mandatoryField")}</p>
            )}
          </span>

          <span>
            <input
              {...register("agreement")}
              type="checkbox"
              id="agreement"
              name="agreement"
              className="clickElements"
            />
            <label htmlFor="agreement">{t("agreement")}</label>
          </span>
        </fieldset>

        <input
          type="submit"
          value={t("send") as string}
          disabled={disabled}
          className={disabled ? "submitButton btnDisabled" : "submitButton"}
        />
      </form>

      <Snackbar open={visibleAlert}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </section>
  );
}

export default RegistrationForm;
