import { useTranslation } from "react-i18next";
import { List, ListItem, ListItemIcon } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import "../cards/sportCard.scss";
import SportList from "../SportList";
import { useNavigate } from "react-router-dom";
import { Event, InfoLabel } from "../../types/types";
import Card from "./Card";
import CardInfo from "./CardInfo";
import { useEffect } from "react";
import { parseDate } from "../../helper/parseDate";

interface Props {
  sport: string;
  sportId?: string;
  upcomingEvent?: Event;
  buttonFlag: boolean;
  header?: string;
  headerImageSrc?: string;
  info?: boolean;
  checkMarks?: boolean;
}

function SportCard({
  sport,
  sportId,
  upcomingEvent,
  buttonFlag,
  header,
  headerImageSrc,
  info,
  checkMarks,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const infoLabel: InfoLabel = t("info", { returnObjects: true });
  //const infoContent: InfoLabel = info
  //  ? t((sport as string) + "Info", { returnObjects: true })
  //  : { where: "", when: "", whatTime: "" };

  let infoContent = {
    where: "",
    when: "",
    date: "",
    whatTime: "",
  };

  if (upcomingEvent) {
    const dateFrom = parseDate(upcomingEvent.from);
    const dateTo = parseDate(upcomingEvent.to);
    const dayList = t("days", { returnObjects: true }) as Array<string>;

    infoContent = {
      where: upcomingEvent?.location,
      when: dayList[dateFrom.day],
      date: dateFrom.date,
      whatTime: dateFrom.time + " - " + dateTo.time,
    };
  }

  const list = t((sport as string) + "Check", { returnObjects: true });

  const btnClickable = () => {
    if (!upcomingEvent)
      return (
        <button className="card-btn notActive" disabled={true}>
          {t("comingSoon")}
        </button>
      );
    else if (
      upcomingEvent &&
      upcomingEvent.registrationCount < upcomingEvent.limit
    ) {
      return (
        <button className="card-btn" onClick={() => handleClick()}>
          {t("joinBtnText")}
        </button>
      );
    } else
      return (
        <button className="card-btn notActive" disabled={true}>
          {t("capacityReached")}
        </button>
      );
  };

  const listItems =
    Array.isArray(list) &&
    list.map((item, index) => {
      return (
        <ListItem key={index}>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          {item}
        </ListItem>
      );
    });

  const heading = () => {
    if (header && !headerImageSrc) return <h2>{header}</h2>;
    else if (headerImageSrc)
      return <img src={headerImageSrc} alt={sport + "HeadingImg"} />;
  };

  const handleClick = () => {
    navigate("/" + sport + "/" + sportId + "/form/" + upcomingEvent?.id);
  };

  return (
    <Card styleClass={"sport-card " + sport}>
      <div className="sports-card-heading">{heading()}</div>

      <div className="card-content">
        {info && <CardInfo infoLabel={infoLabel} infoContent={infoContent} />}

        {checkMarks && (
          <List className="sport-card-list">
            {Array.isArray(list) ? listItems : <></>}
          </List>
        )}

        {sport === "other" && <SportList />}

        {upcomingEvent && (
          <p className="card-content-capacity">
            {t("capacity")}: {upcomingEvent?.registrationCount + 1}/
            {upcomingEvent?.limit + 1}
          </p>
        )}

        {buttonFlag && btnClickable()}
      </div>
    </Card>
  );
}

export default SportCard;
